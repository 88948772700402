export const environment = {
  production: true,
  URL: "https://streaming.pouchnation.com",
  API_V3: "https://gw.pouchnation.com",
  GOOGLE_TAG_MANAGER_ID: "G-WGSQF2B4FT",
  STREAM_CHAT: {
    key: "jz7tz3qwadk9",
    secret: "h6scpkms56q9mp9dqusjkhraw5hbm4uhbq93zh5heud4hs7yp5yp7g83nft6x693",
  },
  FIREBASE: {
    apiKey: "AIzaSyC6peWekttDuVlN_3cvtNL1t8gUklYFR2Q",
    authDomain: "pouchstreaming.firebaseapp.com",
    databaseURL: "https://pouchstreaming.firebaseio.com",
    projectId: "pouchstreaming",
    storageBucket: "pouchstreaming.appspot.com",
    messagingSenderId: "564515744020",
    appId: "1:564515744020:web:d0ab9b169275f8dd273a01",
    measurementId: "G-KRRV0VZQT7",
  },
};
