export const razorback = {
  testMode: false,
  slug: "razorback",
  date: "15 May 2020",
  time: "8:30 PM (PHT)",
  eventStartTimestamp: 1589545800000,
  eventEndTimestamp: 1589554800000,
  officialHashtag: "#rzrbk #pouchnation",
  officialColor: "orange",
  eventName: "Razorback: Rise Against Hunger",
  organizer: "PouchNATION and Razorback",
  shareLink: "https://myclique.app/live/razorback",
  videoLink:
    "https://de623a8b9bacb203.mediapackage.ap-southeast-1.amazonaws.com/out/v1/ef5f1f0e87274841aa933c7aaa2583ec/index.m3u8",
  description: "This description will show when link is shared.",
};
